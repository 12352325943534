export enum NotificationType {
  NEW_TASK = "new_task",
  TASK_RESERVED = "task_reserved",
  CODE_SUBMITTED = "code_submitted",
  CODE_ACCEPTED = "code_accepted",
  CODE_REJECTED = "code_rejected",
  INTERNAL_ERROR = "internal_error",
  SNAPSHOT_NOT_DELIVERED = "not_delivered",
  VALIDATION_FAILED = "error",
}
